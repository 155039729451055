import React from 'react'

import Layout from 'components/Layout'
import Marketplace from 'components/Marketplace'

export default function MarketplacePage() {
  return (
    <Layout webview className="marketplace-page">
      <Marketplace webview />
    </Layout>
  )
}
